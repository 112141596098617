import { FormatedImage } from '@pretto/website/src/components/FormatedImage/FormatedImage'
import type { PromiseFormated } from '@pretto/website/src/templates/blocs/types/promisies.type'

import * as S from './HeroButton.styles'

export type HeroButtonProps = {
  arePromisesDisplayed?: boolean
  buttonLabel: string
  buttonUrl: string
  description: string
  image: string
  promises: PromiseFormated[]
  title: string
}

export const HeroButton: React.FC<HeroButtonProps> = ({
  arePromisesDisplayed,
  buttonLabel,
  buttonUrl,
  description,
  image,
  promises,
  title,
  ...props
}) => (
  <S.NewBlocRow isHero isHeroWithPromises={arePromisesDisplayed && promises.length > 1} {...props}>
    <S.ContentWrapper>
      <S.Title>{title}</S.Title>
      <S.Description allowedTypes={['strong']}>{description}</S.Description>
      <S.MobileImageWrapper>
        <FormatedImage src={image} format="rectangle" isRounded />
      </S.MobileImageWrapper>
      <S.Button href={buttonUrl}>{buttonLabel}</S.Button>
    </S.ContentWrapper>

    <S.ImageWrapper>
      <FormatedImage src={image} format="rectangle" isRounded />
    </S.ImageWrapper>

    {arePromisesDisplayed && promises.length > 1 && <S.Promises promises={promises} />}
  </S.NewBlocRow>
)
